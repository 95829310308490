$(".ico-menu").hover(
  function () {
    $(".hidden-part").addClass("hover");
  },
  function () {
    $(".hidden-part").removeClass("hover");
  }
);

$(".slider").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  autoplay: true,
});

$(".menu-item-has-children").hover(
  function () {
    $(".slick-slider").addClass("js-hover");
  },
  function () {
    $(".slick-slider").removeClass("js-hover");
  }
);
